import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { UnstyledList, Nav } from "./commons"
import Linkedin from "../images/linkedin.png"
import Instagram from "../images/instagram.png"
import Behance from "../images/behance.png"

const SocialNetworkList = styled(UnstyledList)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: right;

  li {
    margin-bottom: 0.4rem;
  }

  img {
    display: block;
  }
`

const StyledFooter = styled.footer`
  width: 12em;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;

  ${Nav} {
    position: relative;
  }

  ${SocialNetworkList} {
    justify-content: center;
  }
`

const Footer = () => (
  <StyledFooter>
    <Nav>
      <UnstyledList>
        <li>
          <Link to="/contact">contact</Link>
        </li>
        <li>
          <Link to="#">archive</Link>
        </li>
      </UnstyledList>
      <SocialNetworkList>
        <li>
          <a href="#">
            <img width={21} alt="Linkedin" src={Linkedin} />
          </a>
        </li>
        <li>
          <a href="#">
            <img width={21} alt="Instagram" src={Instagram} />
          </a>
        </li>
        <li>
          <a href="#">
            <img width={21} alt="Behance" src={Behance} />
          </a>
        </li>
      </SocialNetworkList>
    </Nav>
  </StyledFooter>
)

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
