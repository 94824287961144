import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import PageTransition from "gatsby-plugin-page-transitions"

import Layout from "../components/layout"
import SEO from "../components/seo"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #EFEADA
  }
`

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8.6rem;
`

const H1 = styled.h1`
  font-weight: 900;
  font-size: 125px;
  font-family: "Playfair Display", serif;
  text-align: right;
  display: flex;
  flex-direction: column;
  color: #000;
  margin-right: 2.8rem;
  margin-bottom: 0;
  line-height: 1.1;
  height: 100%;

  span:last-child {
    padding-right: 0.8rem;
  }
`

const H2 = styled.h2`
  font-family: "visionbold";
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 6.5px;
  margin-bottom: 1.1rem;

  a {
    text-decoration: none;
    color: #000;
  }

  small {
    text-transform: none;
    font-size: 1.1rem;
    letter-spacing: 4.2px;
  }
`

const Content = styled.div`
  color: #000;
  margin-top: 4.5rem;
  border-left: 2px solid #000;
  padding-left: 2.8rem;
  margin-left: 13.9rem;

  p {
    font-family: "visionlight";
    font-size: 15px;
    letter-spacing: 3px;
    line-height: 1.6;
    padding-right: 1rem;
  }
`

const Button = styled.button`
  font-family: "visionbold";
  background-color: #000;
  color: #fff;
  border: none;
  text-transform: uppercase;
  font-size: 1.15rem;
  padding: 0.35rem 1.45rem;
  margin-top: -1rem;
  letter-spacing: 3.2px;

  a {
    text-decoration: none;
    color: #fff;
  }
`

function ContactPage() {
  return (
    <PageTransition>
      <Layout>
        <SEO title="Contact" />
        <GlobalStyle />
        <Root>
          <header>
            <H1>
              <span>contactez moi</span>
              <span>n'hésitez plus !</span>
            </H1>
          </header>
          <Content>
            <H2>
              <a href="mailto:maeva_raumel@orange.fr">Contact@Raumel.com</a>
            </H2>
            <p>
              Un projet ? Une question ? Un aveu ? <br />
              Contactez moi et parlez-moi de votre projet en détail, <br />
              je vous répondrai dans le plus bref délai.
            </p>
            <Button>
              <a href="mailto:maeva_raumel@orange.fr">écrivez-moi</a>
            </Button>
          </Content>
        </Root>
      </Layout>
    </PageTransition>
  )
}

export default ContactPage
