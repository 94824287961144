import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"

import Logo from "./logo"
import { UnstyledList, Nav } from "./commons"

const StyledHeader = styled.header`
  height: 100vh;
  width: 12em;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 2.7rem;
  padding-bottom: 3.6rem;
`

const H1 = styled.h1`
  margin: 0;
`

const Header = () => (
  <StyledHeader>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <Nav>
      <UnstyledList>
        <li>
          <H1>
            <Link to="/">
              <Logo />
            </Link>
          </H1>
        </li>
        <li>
          <Link to="/about">à propos</Link>
        </li>
      </UnstyledList>
    </Nav>
  </StyledHeader>
)

export default Header
